import { Button, Col, Form, Input, Radio, Row, Typography, message } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UpdateSchoolInput, useUpdateSchoolMutation } from '~/alpha/core'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useAlphaStore } from '~/context'
import { useEmailPhoneStore } from '~/context/user'
import { useSchoolMeApiQuery } from '~/hooks/useSchoolMeApiQuery'
import { checkIfValidPhone } from '~/school-utils/validations'
import Api from '~/utils/api'
import {
  countRequiredQuestionsLength,
  getCurrentPrefectureQuestions,
} from '~/utils/questions'
import { Dashboard } from '../layout/Dashboard'
import SettingsModal from '../modal/SettingModal/SettingModal'
import ScreenLoading from '../shared/loading/ScreenLoading'

const { Text } = Typography

interface IStudentStatisticBase {
  schoolGrade: number
  totalTestStudents: number
}

interface IHomeStatistic {
  totalRegisterStudent: {
    schoolGrade: number
    totalStudents: number
  }[]
  totalTestStudent: IStudentStatisticBase[]
  totalQuestionStudent: {
    schoolGrade: number
    totalQuestionStudents: number
  }[]
}

interface IHomeTestResStatistic {
  totalTestStudent: IStudentStatisticBase[]
}

interface IHomeStatisticTable {
  /**
   * @example 全学年
   */
  schoolGrade: number | string

  /**
   * @example 全学年
   */
  schoolGradeName: number | string

  /**
   * 入力済
   */
  totalTestStudents: number
  /**
   * 入力済
   */
  totalQuestionStudents: number
  totalStudents: number
}

const HomePage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [dataTable, setDataTable] = useState<IHomeStatisticTable[]>()
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { school, teacher, setTeacher } = useAlphaStore()

  const { setPhone, setEmail } = useEmailPhoneStore()

  const prefectureCode = school?.attributes?.prefectureCode

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const getStudentData = async (questionLength: number) => {
    const apiUrl = `/alpha/v1/school/home-statistics?questionLength=${questionLength}`

    const { data } = await Api.get<IHomeStatistic>(apiUrl)

    if (!data) {
      message.error(t('エラーが発生しました。'), 8)
      console.error('data:', data)
      return
    }

    const cloneData = _.cloneDeep(data.totalRegisterStudent)

    const questionData = formatData(
      _.cloneDeep(data.totalQuestionStudent),
      'schoolGrade',
    )

    if (data.totalTestStudent.length === 0) {
      for (const line of cloneData) {
        data.totalTestStudent.push({
          schoolGrade: line.schoolGrade as number,
          totalTestStudents: line.totalStudents,
        })
      }
    }

    const testData = formatData(
      _.cloneDeep(data.totalTestStudent),
      'schoolGrade',
    )

    const dataResult: IHomeStatisticTable[] = []
    let totalRegisters = 0
    let totalQuestions = 0
    let totalTests = 0

    cloneData.forEach((clone) => {
      totalRegisters += clone.totalStudents

      const currentTotalQuestionStudentsNumber =
        questionData[clone.schoolGrade]?.totalQuestionStudents ?? 0

      totalQuestions += currentTotalQuestionStudentsNumber

      const currentTotalTestStudentsNumber =
        testData[clone.schoolGrade]?.totalTestStudents ?? 0

      totalTests += currentTotalTestStudentsNumber

      dataResult.push({
        ...clone,
        schoolGradeName: `${clone.schoolGrade}学年`,
        totalQuestionStudents: currentTotalQuestionStudentsNumber,
        totalTestStudents: currentTotalTestStudentsNumber,
      })
    })

    dataResult.push({
      schoolGrade: '全学年',
      schoolGradeName: '全学年',
      totalStudents: totalRegisters,
      totalQuestionStudents: totalQuestions,
      totalTestStudents: totalTests,
    })

    setDataTable(dataResult)
  }

  const formatData = (list, key) => {
    const result = {}
    list.forEach((value) => {
      result[value[key]] = value
    })
    return result
  }

  useEffect(() => {
    if (!prefectureCode) return

    const questionList = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
    )

    getStudentData(countRequiredQuestionsLength(questionList))

    // TODO
    // updateWorkbookFromPublic(t, 'report/集計結果_中学校用')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementarySchool, prefectureCode])

  const [showSavedNotification, setShowSavedNotification] = useState(false)
  const {
    data: teacherData,
    loading: teacherLoading,
    refresh: refreshTeacherData,
  } = useSchoolMeApiQuery()

  const [updateSchoolMutation, { loading }] = useUpdateSchoolMutation()

  const [form] = Form.useForm()

  useEffect(() => {
    if (teacherLoading) return
    if (!teacherData) {
      return
    }

    const _school = teacherData.school

    if (_school) {
      form.setFieldsValue({
        email: _school.attributes.email,
        telephone: _school.attributes.telephone,
        isStudentInputActive: _school.attributes.isStudentInputActive
          ? '1'
          : '0',
      })
    }
  }, [teacherLoading, teacherData, form])

  const onFinish = async (values: {
    email: string
    telephone: string
    isStudentInputActive: '1' | '0'
  }) => {
    if (!school || prefectureCode == null) {
      message.error(`${t('エラーが発生しました。')} School is not available!`)
      return
    }

    if (!teacher) {
      message.error(`${t('エラーが発生しました。')} Teacher is not available!`)
      return
    }

    const isStudentInputActive = values.isStudentInputActive === '1'

    const variables: { input: UpdateSchoolInput } = {
      input: {
        schoolId: school._id,
        attributes: {
          ...values,
          isStudentInputActive,
        },
      },
    }

    try {
      const res = await updateSchoolMutation({ variables })
      if (res.errors) {
        message.error(
          `${t('エラーが発生しました。')} [${res.errors[0]?.message}]`,
        )
        console.error('res.errors:', res.errors)
        return
      }

      const updateSchool = res.data?.updateSchool
      if (!updateSchool?.success) {
        message.error(`${t('エラーが発生しました。')} [${updateSchool?.error}]`)
        console.error('updateSchool?.error:', updateSchool?.error)
        return
      }

      // successfully updated
      setTeacher({
        ...teacher,
        school: {
          ...teacher.school,
          attributes: {
            ...teacher.school.attributes,
            ...values,
            isStudentInputActive,
          },
        },
      })

      setPhone(values.telephone)
      setEmail(values.email)

      setShowSavedNotification(true)
      setTimeout(() => {
        setShowSavedNotification(false)
      }, 2000)

      refreshTeacherData()

      const questionList = getCurrentPrefectureQuestions(
        prefectureCode,
        school.attributes.schoolCode,
        isElementarySchool,
      )

      await getStudentData(countRequiredQuestionsLength(questionList))
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${JSON.stringify(err)}]`)
      console.error('when updateSchoolMutation, error:', err)
    }
  }

  return (
    <>
      {school ? (
        <Dashboard selectedMenu={1} navbar={t('ホーム')}>
          {showSavedNotification ? (
            <CompletionLayout message={t('登録完了')} />
          ) : (
            <>
              <Row className="p-3">
                <div className="flex w-full flex-col">
                  <div className="text-base font-bold text-center">
                    {school?.attributes.schoolName}
                  </div>

                  <div className="my-1 text-xs font-bold text-center">
                    {school?.attributes.schoolAddress}
                  </div>

                  <div className="mt-3 mb-7 text-sm font-bold text-center">
                    {teacher &&
                      t('先生', {
                        familyName: teacher.familyName,
                        givenName: teacher.givenName,
                      })}
                  </div>
                </div>
              </Row>

              <Row
                className="w-full justify-center"
                style={{ padding: '12px' }}
              >
                <div className="flex justify-between">
                  <div>
                    <table className="table-static-home">
                      <thead>
                        <tr>
                          <th colSpan={2} />
                          <th colSpan={2} className="px-1 pb-1">
                            {t('体力テスト')}
                            <Button
                              type="primary"
                              className="ml-4 my-1"
                              onClick={() => {
                                setIsSettingModalOpen(true)
                              }}
                            >
                              {t('設定')}
                            </Button>
                          </th>
                          <th colSpan={2} className="px-1 pb-1">
                            {t('アンケート')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table-header">
                          <td className="school-grade-name">{t('学年')}</td>
                          <td className="border-right">
                            <span>{t('在籍人数')}</span>
                          </td>
                          <td>
                            <span className="color-success">{t('入力済')}</span>
                          </td>
                          <td className="border-right">
                            <span className="color-red">{t('未入力')}</span>
                          </td>
                          <td>
                            <span className="color-success">{t('入力済')}</span>
                          </td>
                          <td>
                            <span className="color-red">{t('未入力')}</span>
                          </td>
                        </tr>
                        {dataTable?.map((item, index) => (
                          <tr className="table-body" key={index}>
                            <td>{t(`${item.schoolGradeName}_`)}</td>
                            <td className="border-right">
                              <span>{item.totalStudents}</span>
                            </td>
                            <td>
                              <span className="color-success">
                                {item.totalTestStudents}
                              </span>
                            </td>
                            <td className="border-right">
                              <span className="color-red">
                                {item.totalStudents - item.totalTestStudents}
                              </span>
                            </td>
                            <td>
                              <span className="color-success">
                                {item.totalQuestionStudents}
                              </span>
                            </td>
                            <td>
                              <span className="color-red">
                                {item.totalStudents -
                                  item.totalQuestionStudents}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="ml-4">
                    <div className="flex justify-between home-menu">
                      <div className="flex">
                        <div className="icon-number">1</div>

                        <div className="image-menu">
                          <img src="solid_children.png" alt="" />
                        </div>

                        <div className="home-menu-content">
                          <span className="home-menu-title">
                            {t('生徒登録をしよう', {
                              student: isElementarySchool ? '児童' : '生徒',
                            })}
                          </span>
                          <span>
                            {t('「情報」から「生徒登録」を選択。', {
                              student: isElementarySchool ? '児童' : '生徒',
                            })}
                            <br />
                            {t(
                              'Excelファイルをダウンロードして登録しましょう。',
                            )}
                          </span>
                        </div>
                      </div>

                      <div
                        className="home-menu-link"
                        onClick={() => history.push('/student_list')}
                      >
                        <span>
                          {t('生徒を登録', {
                            student: isElementarySchool ? '児童' : '生徒',
                          })}
                        </span>
                        <img src="next_blue.png" alt="" />
                      </div>
                    </div>

                    <div className="flex justify-between home-menu">
                      <div className="flex">
                        <div className="icon-number">2</div>
                        <div className="image-menu">
                          <img src="qr-code-line.png" alt="" />
                        </div>
                        <div className="home-menu-content">
                          <span className="home-menu-title">
                            {t('サインインコードを印刷しよう')}
                          </span>
                          <span>
                            {t('サインインコードを印刷して配りましょう。')}
                          </span>
                        </div>
                      </div>

                      <div
                        className="home-menu-link"
                        onClick={() => history.push('/school_year_info')}
                      >
                        <span>{t('サインインコードを表示')}</span>
                        <img src="next_blue.png" alt="" />
                      </div>
                    </div>

                    <div className="flex justify-between home-menu">
                      <div className="flex">
                        <div className="icon-number">3</div>
                        <div className="image-menu">
                          <img src="run_home.png" alt="" />
                        </div>
                        <div className="home-menu-content">
                          <span className="home-menu-title">
                            {t('体力テストの結果を登録しよう')}
                          </span>
                          <span>
                            {t('「入力」から「体力テスト」を選択。')}
                            <br />
                            {t(
                              '生徒ページでの入力、Excelファイルでの登録も可能です。',
                              {
                                student: isElementarySchool ? '児童' : '生徒',
                              },
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className="home-menu-link"
                        onClick={() => history.push('/input')}
                      >
                        <span>{t('体力テストを入力')}</span>
                        <img src="next_blue.png" alt="" />
                      </div>
                    </div>
                    <div className="flex justify-between home-menu">
                      <div className="flex">
                        <div className="icon-number">4</div>
                        <div className="image-menu">
                          <img src="survey.png" alt="" />
                        </div>
                        <div className="home-menu-content">
                          <span className="home-menu-title">
                            {t('アンケート結果を登録しよう')}
                          </span>
                          <span>
                            {t('「入力」から「アンケート」を選択。')}
                            <br />
                            {t(
                              '生徒ページでの入力、Excelファイルでの登録も可能です。',
                              {
                                student: isElementarySchool ? '児童' : '生徒',
                              },
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className="home-menu-link"
                        onClick={() => history.push('/input')}
                      >
                        <span>{t('アンケートを入力')}</span>
                        <img src="next_blue.png" alt="" />
                      </div>
                    </div>
                    <div className="flex justify-between home-menu">
                      <div className="flex">
                        <div className="icon-number">5</div>
                        <div className="image-menu">
                          <img src="collection-play-fill.png" alt="" />
                        </div>
                        <div className="home-menu-content">
                          <span className="home-menu-title">
                            {t('動画コンテンツを視聴しよう')}
                          </span>
                          <span>
                            {t('「運動コンテンツ」、「運動プログラム」と')}
                            <br />
                            {t(
                              '「体力テストお手本動画」を視聴することができます。',
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className="home-menu-link"
                        onClick={() => history.push('/movie')}
                      >
                        <span>{t('動画コンテンツを視聴')}</span>
                        <img src="next_blue.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <div className="flex justify-center pt-2 pb-4">
                {teacher && school && (
                  <Form
                    form={form}
                    layout="vertical"
                    className="max-w-400"
                    initialValues={{
                      email: school.attributes.email,
                      telephone: school.attributes.telephone,
                      isStudentInputActive: school.attributes
                        .isStudentInputActive
                        ? '1'
                        : '0',
                    }}
                    onFinish={onFinish}
                    hideRequiredMark
                  >
                    <Row gutter={10}>
                      <Col span={24}>
                        <Form.Item
                          name="telephone"
                          label={
                            <>
                              <Text className="text-xs font-bold">
                                {t('学校電話番号')}
                              </Text>
                              <Button
                                type="primary"
                                className="ant-btn-ti ml-2"
                                danger
                              >
                                <div className="h-full flex justify-items-center items-center">
                                  {t('必須')}
                                </div>
                              </Button>
                            </>
                          }
                          rules={[
                            {
                              required: true,
                              message: t('学校電話番号を入力して下さい。'),
                            },
                            () => ({
                              validator(_, value) {
                                if (!value || checkIfValidPhone(value)) {
                                  return Promise.resolve()
                                }

                                return Promise.reject(
                                  new Error(
                                    t(
                                      '電話番号を半角数字で正しく入力してください。',
                                    ),
                                  ),
                                )
                              },
                            }),
                          ]}
                        >
                          <Input className="bg-gray-150" disabled={loading} />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="email"
                          label={
                            <>
                              <Text className="text-xs font-bold">
                                {t('学校代表者メールアドレス')}
                                <br />
                                <p>{t('ALPHA担当者とのメール連絡用です。')}</p>
                              </Text>
                              <Button
                                type="primary"
                                className="ant-btn-ti ml-2"
                                danger
                              >
                                <div className="h-full flex justify-items-center items-center">
                                  {t('必須')}
                                </div>
                              </Button>
                            </>
                          }
                          rules={[
                            {
                              required: true,
                              message: t(
                                '学校代表者メールアドレスを入力して下さい。',
                              ),
                            },
                            {
                              type: 'email',
                              message: t(
                                '学校代表者メールアドレスを正しく入力してください。',
                              ),
                            },
                          ]}
                        >
                          <Input className="bg-gray-150" disabled={loading} />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="isStudentInputActive"
                          label={
                            <Text className="text-xs font-bold">
                              {t('児童・生徒ALPHAでの測定結果の入力設定')}
                            </Text>
                          }
                        >
                          <Radio.Group>
                            <Radio key="input-on" value="1" checked={true}>
                              {t('入力可')}
                            </Radio>
                            <Radio key="input-off" value="0">
                              {t('入力不可')}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col span={24} className="text-center">
                        <Form.Item>
                          <Button
                            className="w-60 rounded"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            {t('登録')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}

                <SettingsModal
                  isVisible={isSettingModalOpen}
                  isElementarySchool={isElementarySchool}
                  onOk={async () => {
                    setIsSettingModalOpen(false)

                    if (!dataTable) {
                      message.error(
                        `${t(
                          'エラーが発生しました。',
                        )} Statistic data is not available!`,
                        8,
                      )
                      return
                    }

                    const { data } = await Api.get<IHomeTestResStatistic>(
                      '/alpha/v1/school/result-statistics',
                    )

                    if (!data) {
                      message.error(t('エラーが発生しました。'), 8)
                      return
                    }

                    const clonedDataTable = _.clone(dataTable)

                    if (data.totalTestStudent.length === 0) {
                      for (const line of clonedDataTable) {
                        data.totalTestStudent.push({
                          schoolGrade: line.schoolGrade as number,
                          totalTestStudents: line.totalStudents,
                        })
                      }
                    }

                    for (const newGradeData of data.totalTestStudent) {
                      for (const line of clonedDataTable) {
                        if (line.schoolGrade === newGradeData.schoolGrade) {
                          line.totalTestStudents =
                            newGradeData.totalTestStudents
                        }
                      }
                    }

                    setDataTable(clonedDataTable)
                  }}
                  onCancel={() => setIsSettingModalOpen(false)}
                  setIsLoading={setIsLoading}
                />
              </div>

              <ScreenLoading isLoading={isLoading} />
            </>
          )}
        </Dashboard>
      ) : (
        <Dashboard selectedMenu={1} navbar={t('ホーム')}>
          <div />
        </Dashboard>
      )}
    </>
  )
}

export default HomePage
