import { isChangeValue } from '~/utils/string'
import { TStudent } from '~/utils/types/api/res'

export const setFormEdit = (
  form: { [x: string]: any },
  data: { attributes: { testResults: any[] }; _id: any },
  testYear: number,
) => {
  const tr = data.attributes.testResults.find(
    (item: { testYear: number }) => item.testYear === testYear,
  )

  form[`weight_${data._id}`] = tr?.sizeTest?.weight
  form[`height_${data._id}`] = tr?.sizeTest?.height
  form[`gripRight1_${data._id}`] = tr?.grip?.gripRight1
  form[`gripRight2_${data._id}`] = tr?.grip?.gripRight2
  form[`gripLeft1_${data._id}`] = tr?.grip?.gripLeft1
  form[`gripLeft2_${data._id}`] = tr?.grip?.gripLeft2
  form[`sitUps_${data._id}`] = tr?.sitUps?.sitUps
  form[`bending1_${data._id}`] = tr?.bending?.bending1
  form[`bending2_${data._id}`] = tr?.bending?.bending2
  form[`sideJump1_${data._id}`] = tr?.sideJump?.sideJump1
  form[`sideJump2_${data._id}`] = tr?.sideJump?.sideJump2
  form[`shuttleRunCount_${data._id}`] = tr?.shuttleRun?.shuttleRunCount
  form[`sprintRunSeconds_${data._id}`] = tr?.sprintRun?.sprintRunSeconds
  form[`standingJump1_${data._id}`] = tr?.standingJump?.standingJump1
  form[`standingJump2_${data._id}`] = tr?.standingJump?.standingJump2
  form[`handballThrow1_${data._id}`] = tr?.handballThrow?.handballThrow1
  form[`handballThrow2_${data._id}`] = tr?.handballThrow?.handballThrow2
  form[`runningTime_${data._id}`] = tr?.enduranceRun?.runningTime
  form[`runningTimeSeconds_${data._id}`] = tr?.enduranceRun?.runningTimeSeconds

  return form
}

export const setFormAll = (data: any[], testYear: number) => {
  let form = {}

  data.forEach((value: any) => {
    form = setFormEdit(form, value, testYear)
  })

  return form
}

/**
 * If the values should be changed, it checks the following conditions in order:
 * - If value is equal to '0' or 0, it returns the original value.
 * - If value is falsy, it returns null.
 * - If none of the above conditions are met, it returns the original value.
 *
 * If the values should not be changed, it returns undefined.
 */
export const convertValue = (
  value: number | string,
  otherValue: number | string,
): number | string | null | undefined => {
  return isChangeValue(value, otherValue)
    ? value === '0' || value === 0
      ? value
      : !value
      ? null
      : value
    : undefined
}

export const convertValueForReqApi = (
  values: {
    gripRight1: string | number
    gripRight2: string | number
    gripLeft1: string | number
    gripLeft2: string | number
    gripMemo: any
    sitUps: string | number
    sitUpsMemo: any
    bending1: string | number
    bending2: string | number
    bendingMemo: any
    sideJump1: string | number
    sideJump2: string | number
    sideJumpMemo: any
    runningTime: string | number
    runningTimeSeconds: string | number
    enduranceRunMemo: any
    shuttleRunCount: string | number
    shuttleRunMemo: any
    sprintRunSeconds: string | number
    sprintRunMemo: any
    standingJump1: string | number
    standingJump2: string | number
    standingJumpMemo: any
    handballThrow1: string | number
    handballThrow2: string | number
    handballThrowMemo: any
    weight: string | number
    height: string | number
  },
  measurableObj: {
    grip?: boolean
    sitUps?: boolean
    bending?: boolean
    sideJump?: boolean
    enduranceRun?: boolean
    shuttleRun?: boolean
    sprintRun?: boolean
    standingJump?: boolean
    handballThrow?: boolean
  },
  idModal: string,
  filteredStudents: TStudent[],
  selectedStudent: { _id: string },
) => {
  let oldData: any | undefined = undefined

  if (idModal) {
    for (let i = 0; i < filteredStudents.length; i++) {
      if (
        // @ts-ignore
        filteredStudents?.[i]?.attributes?.testResults?.[0]?.studentId ===
        idModal
      ) {
        oldData = filteredStudents?.[i]?.attributes?.testResults?.[0]
      }
    }
  } else {
    oldData = undefined
  }

  return {
    studentId: selectedStudent._id,
    gripTest: {
      grip: {
        gripRight1: convertValue(values.gripRight1, oldData?.grip?.gripRight1),
        gripRight2: convertValue(values.gripRight2, oldData?.grip?.gripRight2),
        gripLeft1: convertValue(values.gripLeft1, oldData?.grip?.gripLeft1),
        gripLeft2: convertValue(values.gripLeft2, oldData?.grip?.gripLeft2),
        isNotMeasurable: measurableObj.grip,
        memo: values.gripMemo,
      },
    },

    sitUpsTest: {
      sitUps: {
        sitUps: convertValue(values.sitUps, oldData?.sitUps?.sitUps),
        isNotMeasurable: measurableObj.sitUps,
        memo: values.sitUpsMemo,
      },
    },
    bendingTest: {
      bending: {
        bending1: convertValue(values.bending1, oldData?.bending?.bending1),
        bending2: convertValue(values.bending2, oldData?.bending?.bending2),
        isNotMeasurable: measurableObj.bending,
        memo: values.bendingMemo,
      },
    },
    sideJumpTest: {
      sideJump: {
        sideJump1: convertValue(values.sideJump1, oldData?.sideJump?.sideJump1),
        sideJump2: convertValue(values.sideJump2, oldData?.sideJump?.sideJump2),
        isNotMeasurable: measurableObj.sideJump,
        memo: values.sideJumpMemo,
      },
    },
    enduranceRunTest: {
      enduranceRun: {
        runningTime: convertValue(
          values.runningTime,
          oldData?.enduranceRun?.runningTime,
        ),
        runningTimeSeconds: convertValue(
          values.runningTimeSeconds,
          oldData?.enduranceRun?.runningTimeSeconds,
        ),
        isNotMeasurable: measurableObj.enduranceRun,
        memo: values.enduranceRunMemo,
      },
    },
    shuttleRunTest: {
      shuttleRun: {
        shuttleRunCount: convertValue(
          values.shuttleRunCount,
          oldData?.shuttleRun?.shuttleRunCount,
        ),
        isNotMeasurable: measurableObj.shuttleRun,
        memo: values.shuttleRunMemo,
      },
    },
    sprintRunTest: {
      sprintRun: {
        sprintRunSeconds: convertValue(
          values.sprintRunSeconds,
          oldData?.sprintRun?.sprintRunSeconds,
        ),
        isNotMeasurable: measurableObj.sprintRun,
        memo: values.sprintRunMemo,
      },
    },
    standingJumpTest: {
      standingJump: {
        standingJump1: convertValue(
          values.standingJump1,
          oldData?.standingJump?.standingJump1,
        ),
        standingJump2: convertValue(
          values.standingJump2,
          oldData?.standingJump?.standingJump2,
        ),
        isNotMeasurable: measurableObj.standingJump,
        memo: values.standingJumpMemo,
      },
    },
    handballThrowTest: {
      handballThrow: {
        handballThrow1: convertValue(
          values.handballThrow1,
          oldData?.handballThrow?.handballThrow1,
        ),
        handballThrow2: convertValue(
          values.handballThrow2,
          oldData?.handballThrow?.handballThrow2,
        ),
        isNotMeasurable: measurableObj.handballThrow,
        memo: values.handballThrowMemo,
      },
    },
    sizeTest: {
      weight: convertValue(values.weight, oldData?.sizeTest?.weight),
      height: convertValue(values.height, oldData?.sizeTest?.height),
    },
  }
}
